import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import { getMethod } from '../Apis/apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import moment from 'moment-timezone';
import Header from './Header';

const Container = styled.div`
    max-width: 480px;
    min-height: 100vh;
    opacity: 1;
    background-color: #FFF;
    background-attachment: fixed;
    margin: 0 auto;
    position: relative;
`

const ContainerDiv = styled.div`
   padding: 20px;
`
const Ul = styled.ul`

`
const Li = styled.li`
    background-color: ${({theme}) => theme.secondary};
    padding: 13px;
    border-radius: 10px;
    border: 1px solid #FFF;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        font-weight: bolder;

        &:first-child {
            color: #FFF;
            font-size: 18px;
        }
        &:nth-child(2),&:nth-child(3){ 
            color: #FFF;
            font-size: 18px;
        }
        &:last-child {
          color: #FFF;
          font-size: 18px;
            div {
              &:last-child {
                color: #FFF600;
                font-size: 21px;
                letter-spacing: .6px;
              }
            }
        }
        
    }
`
const ModenNumberFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  font-size: 15px;

  div {
    text-align: center;
    font-weight: bolder;
  }

  p {
    margin-bottom: 0;
    font-weight: lighter;
    color: #FFF;
  }
`;

const ResultNum = styled.div`
    font-weight: bolder;
    color: ${props => props.theme.primary};
    letter-spacing: .6px;
    margin-bottom: 0;
    font-size: 18px;
`

const Result = () => {
  const [result,setResult] = useState([]);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  const date = useParams();
  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await getMethod(`/number/filter?date=${date.date}`);
      if (res) {
        setLoading(false);
        if (res.con) {
          if (res.data) {
            setResult(res.data);
          }
        }
      }
    })();
  }, [date]);
  return (
    <Container>
      <Header status={true} />
      <ContainerDiv>
        {result.length > 0 ? (
            result.map((res, index) => {
              if ((res.time === "12:00 PM") || 
                  (res.time === "04:30 PM") 
                ) {
                return (
                  <Li key={index}>
                    <span>
                      <div>Time</div>
                      <div>{res.time}</div>
                    </span>
                    <span>
                        <div>Set</div>
                        <div>
                          {res.set.split(".")[0]}.
                          {
                            res.set.split(".")[1].slice(0, -1)
                          }
                          <div style={{color: "#FFF600",display: 'inline-block'}}>
                            {res.set.split(".")[1][res.set.split(".")[1].length - 1]}
                          </div>
                        </div>
                    </span>
                    <span id='value'>
                      <div>Value</div>
                      <div>
                          {res.value.split(".")[0]}.
                          {
                            res.value.split(".")[1].slice(0, -1)
                          }
                          <div style={{color: "#FFF600",display: 'inline-block'}}>
                            {res.value.split(".")[1][res.value.split(".")[1].length - 1]}
                          </div>
                        </div>
                    </span>
                    <span>
                      <div>Result</div>
                      <div>{res.result}</div>
                    </span>
                </Li>
                );
              }
            })
        ) : (
          <div style={{ textAlign: "center", marginTop: '20px', color: 'red' }}>မှတ်တမ်းမရှိပါ</div>
        )}
        {result.length > 0 &&
          result.map((res, index) => {
            if (res.time === "09:30 AM" || res.time === "02:00 PM") {
              return (
                <Li key={index}>
                  <ModenNumberFlex>
                    <div style={{ width: "30%" }}>
                      {
                        <div style={{ fontSize: "18px", fontWeight: "600" }}>
                          {res.time}
                        </div>
                      }
                    </div>
                    <div style={{ width: "35%" }}>
                      Modern <ResultNum>{`${res.set.split(".")[0].slice(-1)}${res.value.split(".")[0].slice(-1)}` ?? "--"}</ResultNum>
                    </div>
                    <div style={{ width: "35%" }}>
                      Internet <ResultNum>{`${res.set.split(".")[1].slice(-1)}${res.value.split(".")[1].slice(-1)}` ?? "--"}</ResultNum>
                    </div>
                  </ModenNumberFlex>
                </Li>
              );
            }
          })}
      </ContainerDiv>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </Container>
  )
}
export default Result;