import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import DashboardLoading from '../../utils/DashboardLoading';
import Sidebar from './Sidebar';
import Header from './Header';
import PasswordInput from '../../utils/PasswordInput';
import { patchMethod } from '../../Apis/apis';
import { useNavigate } from 'react-router-dom';

const ContainerFlex = styled.div`
   display: flex;
   align-items: flex-start;
   height: 100vh;
`

const HeaderContentContainer = styled.div`
   width: 100%;
   height:100%;
`
const ContentContainerDiv = styled.div`
   padding: 20px;
   height:90%;
`
const H5 = styled.h5`
    margin-bottom: 0;
    color: #333;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    &:after {
        content: "";
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
`

const SubmitBtn = styled.button`
   padding: 10px 25px;
   font-size: 16px;
   letter-spacing: 1px;
   float: right;
   margin-top: 10px;
`

const Changepass = () => {
  const [loading,setLoading] = useState(false);
  const oldRef = useRef();
  const newRef = useRef();
  const navigate = useNavigate();
  const changeHandler = async e => {
      e.preventDefault();
      setLoading(true);
      let data = {
         oldpass: oldRef.current.value, 
         newpass: newRef.current.value, 
      };

      let token = localStorage.getItem("bm2d_auth");
      if(token){
        const res = await patchMethod(`/change-password`, data, token);
        if(res){
            if(res.message === "jwt malformed"){
                navigate("/admin/login");
            };
            if(res.message === "jwt expired"){
                navigate("/admin/login");
            };
            if(res.con){
                setLoading(false);
                alert(res.message);
                oldRef.current.value = "";
                newRef.current.value = "";
                navigate("/admin/login");
                if(token){
                    localStorage.removeItem("token")
                }
            }else{
                setLoading(false);
                if(res.message === "invalid token"){
                    navigate("/admin/login");
                    return;
                };

                if(res.message === "jwt expired"){
                    navigate("/admin/login");
                    return;
                };
                if(res.message === "No found user!"){
                    navigate("/admin/login");
                    return;
                }
                alert(res.message)
            }
        }
      }
      
  }
  return (
    <>
        <ContainerFlex>
            <Sidebar />
            <HeaderContentContainer>
                <Header />
                <ContentContainerDiv>
                    <div className='card h-100'>
                        <div className='card-header' style={{
                                backgroundColor: "transparent",
                                borderBottom: 'none',
                                padding: '20px',
                                position: 'relative'}}>
                            <H5>Change Password</H5>
                        </div>
                        <div className='card-body'>
                             <div className='row'>
                                <div className='col-6'>
                                    <form onSubmit={changeHandler}>
                                        <PasswordInput name="Old Password" forInput="oldpass" placeHolder="Enter the old password" passRef={oldRef} />
                                        <PasswordInput name="New Password" forInput="newpass" placeHolder="Enter the new password" passRef={newRef} />
                                        <SubmitBtn type='submit' className='btn btn-primary text-left'>Submit</SubmitBtn>
                                    </form>
                                </div>
                             </div>
                        </div>
                    </div>
                </ContentContainerDiv>
            </HeaderContentContainer>
        </ContainerFlex>
        {
        
            createPortal( loading && <DashboardLoading />, document.getElementById("portal"))
        }
    </>
  )
}

export default Changepass;
