import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {liveHandler} from '../store/liveNumSlice';
import {io} from "socket.io-client";
import { getMethod } from '../Apis/apis';
import { FiCheck,FiClock } from 'react-icons/fi';

const ContainerDiv = styled.div`
   padding: 15px 20px 0 20px;
   background-color: #f5f5f5;
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`

const LiveNumber = styled.h1`
   text-align: center;
   user-select: none;
   color: #34ac1f;
   font-size: 80px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #b3b3b3, 0 -2px 1px #fff;
   margin-bottom: 10px;
`
const LiveNumberAnimation = styled.h1`
   text-align: center;
   user-select: none;
   color: #34ac1f;
   font-size: 80px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #b3b3b3, 0 -2px 1px #fff;
   margin-bottom: 10px;
   animation: ${TextBlink} 4s linear infinite;
`
const UpdatedDate = styled.p`
    margin-bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.fontColor};
`

const UpdatedIcon = styled.span`
   margin-right: 5px;
`
let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
const Live = () => {

    const [animate12,setAnimate12] = useState(false);
    const [animate16,setAnimate16] = useState(false);
    const [check12,setCheck12] = useState(false);
    const [check16,setCheck16] = useState(false);
    const [result,setResult] = useState("!!");
    const [serverTime,setServerTime] = useState();
    const [timmer,setTimmer] = useState(false);

    const dispatch = useDispatch();
    let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/time`);
        socket.connect();
        socket.on("STime", res => {
            setServerTime(res)
            return () => {
                socket.disconnect();
            };
        });
    },[]);

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
            socket.connect();
            socket.on("live", async res => {
                let timeRes = await getMethod(`/number/time`);
                if(timeRes)
                {
                    if(timeRes.con){
                        if(timeRes.data > "08:00:00" && timeRes.data < "11:59:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            };
                        };
                        if(timeRes.data > "12:05:00" && timeRes.data < "16:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            };
                        };
                    };
                };
            });
            return () => {
            socket.disconnect();
        };
    },[]);

    useEffect(()=>{
        let morning = "08:00:00";
        let endMorning = "11:59:58";
        let evening = "12:05:00";
        let endEvening = "16:29:58";

        if(serverTime > morning && serverTime < "16:30:05"){
            setTimmer(true);
        }else{
            setTimmer(false);
        };
    
        if(serverTime > "11:59:59" && serverTime < "12:04:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "12:00 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                };
            })();
        };

        if(serverTime > "16:29:59" && serverTime < "23:59:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "04:30 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if (serverTime > morning && serverTime < endMorning){
            setAnimate12(true);
            setCheck12(true);
        }else{
            setAnimate12(false);
            setCheck12(false);
        };

        if (serverTime > evening && serverTime < endEvening){
            setAnimate16(true);
            setCheck16(true);
        }else{
            setAnimate16(false);
            setCheck16(false);
        };
    },[serverTime]);

  return (
    <ContainerDiv>
        {
            animate12 || animate16 ? <LiveNumberAnimation>{result}</LiveNumberAnimation> : <LiveNumber>{result}</LiveNumber>
        }
        <UpdatedDate>
             <div style={{marginRight: '5px', display: 'flex',alignItems: 'center'}}> 
                <UpdatedIcon>
                    {
                        (check12 || check16)?
                        <FiClock color="#34ac1f" size="20px" />
                        :
                        <FiCheck color="#34ac1f" size="20px" />
                    }
                </UpdatedIcon>
            </div>
            <span style={{fontSize: '14px'}}>Updated: {currentDate} {timmer ? serverTime : "16:30:04"}</span>
        </UpdatedDate>
    </ContainerDiv>
  )
}

export default Live
