import React from 'react';
import DailyResult from './DailyResult';
import Live from './Live';

const Home = () => {
  return (
    <>
      <Live />
      <DailyResult />
    </>
  )
}

export default Home
