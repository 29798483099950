import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { getMethod } from '../Apis/apis';
import Close from '../utils/Close';
import Header from "./Header";
import Home from './Home';
import moment from 'moment-timezone';

const Container = styled.div`
    max-width: 480px;
    height: 100vh;
    overflow: scroll;
    opacity: 1;
    margin: 0 auto;
    position: relative;
    background-color: #f5f5f5;
    
`

const Main = () => {
  const [close, setClose] = useState(true);
  useEffect(()=>{
      if(moment().format('dddd') === "Sunday"){
          setClose(true);
      }else{
          setClose(false)
      };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[moment().format('dddd')]);
  return (
    <Container>
        <Header />
        {
          close ?
          <Close />:
          <Home />
        }
    </Container>
  )
}

export default Main;