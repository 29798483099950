import React from 'react'
import styled from 'styled-components'
import closeImg from '../images/background/close.png'
const ContainerDiv = styled.div`
   padding: 20px;
   height: 90%;
   display: flex;
   align-items: center;
   justify-content: center;

   img {
        width: 80%;
   }
`

const Close = () => {
  return (
    <ContainerDiv>
       <img src={closeImg} alt="close" />
    </ContainerDiv>
  )
}

export default Close
