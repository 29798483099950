import React, { forwardRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import calendar from '../images/icons/calendar.png';
import moment from 'moment-timezone';
import { FaChevronLeft } from 'react-icons/fa';

const HeaderDiv = styled.div`
   padding: 14px 20px;
   display:flex;
   justify-content: space-between;
   align-items: center;
   background-color: ${({theme}) => theme.primary};
   box-shadow: 2px 2px 4px #a8a8aa;
   border-bottom: 1px solid #ccc;

   h5 {
     margin: 0;
     font-weight: 600;
     font-size: 20px;
     color: black;
   }
`

const CalendarBtn = styled.button`
     border: 1px solid #ccc;
     outline: none;
     border-radius: 3px;
     background-color: transparent;
     cursor: pointer !important;
`

const Header = ({status}) => {
  const [startDate, setStartDate] = useState(moment().tz("Asia/Yangon").toDate());
  const navigate = useNavigate();
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
        <img src={calendar} alt="mes" style={{width: "30px"}}/>
    </div>
));


  return (
    <HeaderDiv>
        <div style={{display: 'flex'}}>
            {
              status &&
              <FaChevronLeft size="16px" style={{marginRight: '15px'}} color="#000" onClick={()=>navigate('/')} cursor="pointer" />
            }
            <h5 style={{margin: 0, fontSize: '20px', fontWeight: 'bolder', color: '#000'}}>Burma 2D</h5>
        </div>
        <div>
          <CalendarBtn>
              <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      navigate(`/result/${date.getFullYear()}-${date.getMonth()+1 < 10?`0${date.getMonth()+1}` : date.getMonth()+1}-${date.getDate() < 10 ? '0'+date.getDate(): date.getDate()}`)
                    }}
                    customInput={<ExampleCustomInput />}
              />
          </CalendarBtn>
        </div>
    </HeaderDiv>
  )
}

export default Header;