import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {io} from "socket.io-client";
import {getMethod} from '../Apis/apis';
import moment from 'moment-timezone';

const ContainerDiv = styled.div`
   padding: 10px 20px;
`
const Ul = styled.ul`
    
`
const Li = styled.li`
    background-color: ${props => props.theme.secondary};
    padding: 12px;
    border-radius: 20px;
    border: 1px solid #FFF;
    user-select: none;
    margin: 5px 5px 15px 5px;
    box-shadow: 2px 2px 4px #a8a8aa;
    
    &:last-child {
        margin-bottom: 0;
    }
    span {
        font-weight: bolder;
        color: #908F94;
        font-size: 18px;  
        p {
          margin-bottom: 0;
          text-align: center;
        }
    };
`
const ModenNumberFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  font-size: 15px;

  div {
    text-align: center;
    font-weight: bolder;
  }

  p {
    margin-bottom: 0;
    font-weight: lighter;
    color: #FFF;
  }
`;

const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`

const TimeResultFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;  
    color: #FFF;
    margin-top: 10px;
    font-size: 15px;

    div {
      text-align: center;
      font-weight: bolder;
    }

    p {
      margin-bottom: 0;
      font-weight: lighter;
      color: #FFF;
    }
`

const SetValueAnimation = styled.p`
    animation: ${TextBlink} 4s linear infinite;
`

const ResultNum = styled.div`
    font-weight: bolder;
    color: ${props => props.theme.primary};
    letter-spacing: .6px;
    margin-bottom: 0;
    font-size: 18px;
`

const TimeDiv = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    padding-bottom: 5px;
`

const DailyResult = () => {
    const [result12,setResult12] = useState("--");
    const [result16,setResult16] = useState("--");
    const [set12,setSet12] = useState('- - - - -');
    const [set16,setSet16] = useState('- - - - -');
    const [value12,setValue12] = useState('- - - - -');
    const [value16,setValue16] = useState('- - - - -');
    const [animate12,setAnimate12] = useState(false);
    const [animate16,setAnimate16] = useState(false);
    const [serverTime,setServerTime] = useState();
    const [modern9,setModern9] = useState("--");
    const [internet9,setInternet9] = useState("--");
    const [modern14,setModern14] = useState("--");
    const [internet14,setInternet14] = useState("--");
    let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/time`);
        socket.connect();
        socket.on("STime", res => {
            setServerTime(res)
            return () => {
                socket.disconnect();
            };
        });
    },[]);

    useEffect(()=>{
       const fetch = async () => {
        let res = await getMethod(`/number/filter?date=${currentDate}`);
            if(res){
               if(res.data){
                if(res.data.length > 0){
                  res.data.map(obj => {
                     if(obj.time === "09:30 AM"){
                        setModern9(`${obj.set.split(".")[0].slice(-1)}${obj.value.split(".")[0].slice(-1)}`);
                        setInternet9(`${obj.set.split(".")[1].slice(-1)}${obj.value.split(".")[1].slice(-1)}`);
                     };

                     if(obj.time === "02:00 PM"){
                        setModern14(`${obj.set.split(".")[0].slice(-1)}${obj.value.split(".")[0].slice(-1)}`);
                        setInternet14(`${obj.set.split(".")[1].slice(-1)}${obj.value.split(".")[1].slice(-1)}`);
                     };

                      if(obj.time === "12:00 PM"){
                        setResult12(obj.result);
                        setSet12(obj.set);
                        setValue12(obj.value);
                      };
    
                      if(obj.time === "04:30 PM"){
                        setResult16(obj.result);
                        setSet16(obj.set);
                        setValue16(obj.value);
                      };
                  });
                };
               }
            }
       };
       fetch();
    },[]);

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
            socket.connect();
            socket.on("live", res => {
                let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
                if(timeconvert > "08:00:00" && timeconvert < "11:59:58"){
                    if(res){
                        setSet12(res.set);
                        setValue12(res.value);
                    };
                };
                
                if(timeconvert > "12:05:00" && timeconvert < "16:29:58"){
                    if(res){
                        setSet16(res.set);
                        setValue16(res.value);
                    };
                };
            });
            return () => {
            socket.disconnect();
        };
    },[]);
    
    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
        socket.connect();
        socket.on("result", res => {
            if(res){
              switch(res.time) {
                case "09:00 AM":
                  setModern9(`${res.set.split(".")[0].slice(-1)}${res.value.split(".")[0].slice(-1)}`);
                  setInternet9(`${res.set.split(".")[1].slice(-1)}${res.value.split(".")[1].slice(-1)}`);
                  break;
                case "09:00 AM":
                  setModern14(`${res.set.split(".")[0].slice(-1)}${res.value.split(".")[0].slice(-1)}`);
                  setInternet14(`${res.set.split(".")[1].slice(-1)}${res.value.split(".")[1].slice(-1)}`);
                  break;
                case "12:00 PM":
                  setResult12(res.result);
                  setSet12(res.set);
                  setValue12(res.value);
                  break;
                case "04:30 PM":
                  setResult16(res.result);
                  setSet16(res.set);
                  setValue16(res.value);
                  break;
                default:
                  return;
              };
            }
        });
        return () => {
          socket.disconnect();
        }
      },[]);

    useEffect(()=>{
        let morning = "08:00:00";
        let endMorning = "11:59:58";
        
        let evening = "12:05:00";
        let endEvening = "16:29:58";

        if (serverTime > morning && serverTime < endMorning){
            setAnimate12(true);
        }else{
            setAnimate12(false);
        };

        if (serverTime > evening && serverTime < endEvening){
            setAnimate16(true);
        }else{
            setAnimate16(false);
        };
    },[serverTime]);
      
  return (
    <ContainerDiv>
       <Ul>
          <Li>
            <TimeDiv>
                  12:00 PM
            </TimeDiv>
            <TimeResultFlex>
                <div style={{width:'40%'}}>Set {animate12 ? <SetValueAnimation>{set12}</SetValueAnimation>:<p>{set12}</p>}</div>
                <div style={{width:'40%'}}>Value {animate12 ? <SetValueAnimation>{value12}</SetValueAnimation>:<p>{value12}</p>}</div>
                <div style={{width:'20%'}}>2D <ResultNum>{result12}</ResultNum></div>
            </TimeResultFlex>
          </Li>
          <Li>
            <TimeDiv>
                  04:30 PM
            </TimeDiv>
            <TimeResultFlex>
                <div style={{width:'40%'}}>Set {animate16 ? <SetValueAnimation>{set16}</SetValueAnimation>:<p>{set16}</p>}</div>
                <div style={{width:'40%'}}>Value {animate16 ? <SetValueAnimation>{value16}</SetValueAnimation>:<p>{value16}</p>}</div>
                <div style={{width:'20%'}}>2D <ResultNum>{result16}</ResultNum></div>
            </TimeResultFlex>
          </Li>
          <hr style={{ borderColor: "#FFF", opacity: 1 }} />
          <Li>
          <ModenNumberFlex>
            <div style={{ width: "30%" }}>
              {
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                    09:30 AM
                </div>
              }
            </div>
            <div style={{ width: "35%" }}>
              Modern <ResultNum>{modern9 ?? "--"}</ResultNum>
            </div>
            <div style={{ width: "35%" }}>
              Internet <ResultNum>{internet9 ?? "--"}</ResultNum>
            </div>
          </ModenNumberFlex>
        </Li>
        <Li>
          <ModenNumberFlex>
            <div style={{ width: "30%" }}>
              {
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                    02:00 PM
                </div>
              }
            </div>
            <div style={{ width: "35%" }}>
              Modern <ResultNum>{modern14 ?? "--"}</ResultNum>
            </div>
            <div style={{ width: "35%" }}>
              Internet <ResultNum>{internet14 ?? "--"}</ResultNum>
            </div>
          </ModenNumberFlex>
        </Li>
       </Ul>
    </ContainerDiv>
  )
}

export default DailyResult
